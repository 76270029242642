<template>
  <div
    class="Checkbox"
    ref="myBox"
    :style="{ width: `${clientWidth}px` }"
    @click="$emit('check')"
  >
    <img v-if="selected" src="../../../assets/icons/check.svg" />
  </div>
</template>

<script>
export default {
  name: 'checkbox',
  data () {
    return {
      clientWidth: null
    }
  },
  mounted () {
    this.clientWidth = this.$refs.myBox.clientHeight
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.Checkbox {
  height: 100%;
  background-color: #d73220;
  border-radius: 5px;
}
img {
  width: 100%;
  object-fit: contain;
}
</style>
