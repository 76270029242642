<template>
  <div class="User__Container">
    <Parallax :images="ParallaxImages()" />
    <div class="User">
      <div class="User_Blur" />
      <div class="User__header">
        <img src="../../assets/logo@3x.png" />
        <!-- <div class="User__appNames">
          <h2 class="User__appSubtitle">MONTE NAPOLEONE</h2>
          <h3 class="User__appName">RETAIL DESIGN PLATFORM</h3>
        </div> -->
      </div>
      <div class="User__content">
        <p class="label">{{ $t('userPage.text') }}</p>
        <div class="User__Fields">
          <div class="field">
            <LoginField
              :input-type="'text'"
              :placeholder="`${$t('firstName')}`"
              :autocompletion="firstName"
              @textChanged="UpdateFirstName($event)"
            />
          </div>
          <div class="field">
            <LoginField
              :input-type="'text'"
              :placeholder="`${$t('lastName')}`"
              :autocompletion="lastName"
              @textChanged="UpdateLastName($event)"
            />
          </div>
        </div>
        <div class="User__Checkboxs">
          <div
            class="User__Checkbox"
            v-for="(key, index) in cguKeys"
            :key="index"
          >
            <CheckboxField
              :text="$t(`cgu.${key}.agree`)"
              :selected="selectedCgus.map(el => el.index).includes(index)"
              @check="checkBox(index)"
              @seeMore="seeMore(index)"
            />
          </div>
        </div>
        <div class="Buttons__Container">
          <CTA
            class="User__Btn"
            :active="cguValid"
            :label="`${$t('validate')}`"
            @clickButton="submit"
            :color="$store.getters['theme/theme'].secondary"
          />
        </div>
      </div>
    </div>
    <modale-cgu
      v-if="seeMoreIndex || seeMoreIndex === 0"
      :title="$t(`cgu.${cguKeys[seeMoreIndex]}.title`)"
      :description="$t(`cgu.${cguKeys[seeMoreIndex]}.content`)"
      @closeModaleCgu="closeModale"
    />
  </div>
</template>

<script>
import LoginField from '../login/components/LoginField'
import ModaleCgu from '@/components/modale/ModaleCgu'
import CTA from '@/components/button/CTA'
import Parallax from '@/components/parallax/Parallax.vue'
import CheckboxField from './components/CheckboxField'

export default {
  name: 'UserPage',
  components: {
    LoginField,
    CTA,
    // AdaptativeImage,
    CheckboxField,
    ModaleCgu,
    Parallax
  },
  data () {
    return {
      firstName: null,
      lastName: null,
      seeMoreIndex: null,
      selectedCgus: []
    }
  },
  created () {
    if (this.cacheInfos) {
      this.firstName = this.cacheInfos.firstName
      this.lastName = this.cacheInfos.lastName
      this.selectedCgus = this.cacheInfos.access
    }
  },
  computed: {
    cacheInfos () {
      return JSON.parse(localStorage.getItem('user-infos'))
    },
    wording () {
      return this.$store.getters['langs/wording']
    },
    placeholder () {
      return 'Email'
    },
    cguKeys () {
      return Object.keys(this.wording.cgu)
    },
    cguTitles () {
      return this.cguKeys.map(el => this.$t(`cgu.${el}.title`))
    },
    cguContents () {
      return this.cguKeys.map(el => this.$t(`cgu.${el}.content`))
    },
    cguValid () {
      const cguKeys = this.selectedCgus.map(el => el.key)
      const hasTerms = cguKeys.filter(el => el.includes('terms')).length > 0
      const hasPrivacy = cguKeys.filter(el => el.includes('privacy')).length > 0
      return hasTerms && hasPrivacy
    }
  },

  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    closeModale (acceptsCgu) {
      const keys = this.selectedCgus.map(el => el.index)
      if (
        (acceptsCgu && !keys.includes(this.seeMoreIndex)) ||
        (!acceptsCgu && keys.includes(this.seeMoreIndex))
      ) {
        this.checkBox(this.seeMoreIndex)
      }
      this.seeMoreIndex = null
    },
    checkBox (nb) {
      const keys = this.selectedCgus.map(el => el.index)
      if (!keys.includes(nb)) {
        const isRequired =
          this.cguTitles[nb].includes('terms') ||
          this.cguTitles[nb].includes('privacy')
        if (isRequired) {
          this.selectedCgus.push({
            key: this.cguKeys[nb],
            index: nb,
            required: true,
            timestamp: new Date().getTime()
          })
        } else {
          this.selectedCgus.push({
            key: this.cguKeys[nb],
            index: nb,
            required: false,
            timestamp: new Date().getTime()
          })
        }
      } else {
        const index = keys.indexOf(nb)
        this.selectedCgus.splice(index, 1)
      }
    },
    seeMore (index) {
      this.seeMoreIndex = index
    },
    submit () {
      const obj = {
        firstName: this.firstName,
        lastName: this.lastName,
        access: this.selectedCgus,
        updatedAt: new Date().getTime()
      }
      localStorage.setItem('user-infos', JSON.stringify(obj))
      this.$router.push({ name: 'lobby' })
    },
    UpdateFirstName (e) {
      this.firstName = e
    },
    UpdateLastName (e) {
      this.lastName = e
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';
.User__Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.User__Image_Bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.User {
  position: relative;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 550px;
  width: 350px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 610px) and (orientation: portrait) {
    height: 75%;
    padding: 1rem;
  }
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    height: 90%;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 350px) {
    width: 90%;
  }
}

.User__Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.User__Image_Bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.User {
  position: relative;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 550px;
  width: 350px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 610px) and (orientation: portrait) {
    height: 75%;
    padding: 1rem;
  }
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    height: 90%;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 350px) {
    width: 90%;
  }
}

.User_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.User__header {
  width: 155px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
    box-sizing: border-box;
  }
}

.title {
  font-family: 'BaronDidotT';
  text-transform: uppercase;
  text-align: center;
  @include fluid-type($min_width, $max_width, $min_font * 1.5, $max_font * 1.5);
}

.User__content {
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  font-family: 'Gotham Medium';
  @include fluid-type($min_width, $max_width, $min_font * 0.9, $max_font * 0.8);
  text-align: center;
  line-height: 1.2;
}

.User__Fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%;
}

.field {
  height: 45%;
}

.Buttons__Container {
  display: flex;
  justify-content: center;
  height: 13%;
}

.User__Btn {
  height: 100%;
  width: 48%;
}

.User__Checkboxs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%;
  width: 100%;
}

.User__Checkbox {
  height: 20%;
}

.User__appNames {
  text-align: center;
  margin: 0 auto;
  h2 {
    font-family: 'Gotham Bold';
    color: rgb(215, 50, 30);
    margin: 0.1rem 0;
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 0.5,
      $max_font * 0.7
    );
  }
  h3 {
    letter-spacing: 0.7px;
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 0.8,
      $max_font * 0.5
    );
  }
}
</style>
