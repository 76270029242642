<template>
  <div class="CheckboxField">
    <checkbox
      class="Checkbox__Component"
      :selected="selected"
      @check="$emit('check')"
    />
    <div class="CheckboxField_Txt" @click="$emit('seeMore')">
      <p v-html="formatText(text)"></p>
    </div>
  </div>
</template>

<script>
import Checkbox from './Checkbox.vue'

export default {
  name: 'checkbox-field',
  components: {
    Checkbox
  },
  data () {
    return {}
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatText (txt) {
      return txt.split('<span>').join('<span class="underline">')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/main.scss';

.CheckboxField {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.CheckboxField_Txt {
  width: 90%;
  margin-left: 10px;
}
p,
p /deep/ span {
  @include fluid-type($min_width, $max_width, $min_font * 1, $max_font * 0.8);
  font-family: 'Gotham Medium';
}

p /deep/ span {
  text-decoration: underline;
}
</style>
