<template>
  <div class="P_Container">
    <div
      v-for="(object, index) in images"
      :key="index"
      class="P_ImagesContainer"
    >
      <img
        :src="require(`@/assets/login/${object.src}`)"
        :style="{
          'margin-left': x * object.force + 'px',
          'margin-top': y * object.force + 'px'
        }"
        class="P_Images"
      />
    </div>
    <div
      v-for="(anim, index) in animations"
      :key="'A' + index"
      class="P_ImagesContainer"
    >
      <img
        v-if="GetAnimImage(anim.src.length) < anim.src.length"
        :src="
          require(`@/assets/login/${anim.src[GetAnimImage(anim.src.length)]}`)
        "
        :style="{
          'margin-left': x * anim.force + 'px',
          'margin-top': y * anim.force + 'px',
          opacity: 0.5
        }"
        class="P_Images"
      />
    </div>
  </div>
</template>

<script>
let animationId = 0

export default {
  data () {
    return {
      x: 0,
      y: 0,
      counter: 0
    }
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    animations: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    window.addEventListener('mousemove', this.OnPointerMove)
    this.Animate()
  },
  beforeDestroy () {
    window.removeEventListener('mousemove', this.OnPointerMove)
    cancelAnimationFrame(animationId)
  },
  methods: {
    Animate () {
      this.counter++
      animationId = requestAnimationFrame(this.Animate)
    },
    OnPointerMove (event) {
      const clientX = this.GetClientX(event) / window.innerWidth - 0.5
      const clientY = this.GetClientY(event) / window.innerHeight - 0.5
      this.x = Math.min(Math.max(clientX, -0.5), 0.5)
      this.y = Math.min(Math.max(clientY, -0.5), 0.5)
    },
    GetClientX () {
      return event.clientX || (event.touches && event.touches[0].clientX) || 0
    },
    GetClientY () {
      return event.clientY || (event.touches && event.touches[0].clientY) || 0
    },
    GetAnimImage (length) {
      return Math.floor(this.counter / 50) % (length * 2)
    }
  }
}
</script>

<style scoped>
.P_Container {
  position: absolute;
  width: 110%;
  height: 110%;
  margin-left: -2%;
  margin-top: -2%;
  display: flex;
}

.P_ImagesContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.P_Images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
